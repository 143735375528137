@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@500;600&display=swap");

/* @font-face {
  font-family: "Avenir";
  src: local("Avenir"), url("path/to/avenir.woff2") format("woff2"),
    url("path/to/avenir.woff") format("woff");
  font-weight: normal;  
  font-style: normal;
} */
.header {
  width: 100%;
  position: relative;
}
.header-heading {
  font-family: "Oswald", sans-serif;
  font-size: 28px;
  text-align: center;
  color: #162b6f;
  padding-top: 20px;
  box-sizing: border-box;
}
.header-nav {
  display: flex;
}
.header-navlinks {
  width: 100%;
  height: 42px;
  padding-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f7f9;
  margin-top: 30px;
}
p a {
  font-family: "Avenir", sans-serif;
  color: black;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.79em;
  margin-left: 16px;
  cursor: pointer;
}
.header-menu_container p {
  font-family: "Avenir", sans-serif;
  color: black;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.79em;
  cursor: pointer;
}
.header-menu_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-color: #f8f7f9;
  padding: 5px;
  position: absolute;
  right: 0;
  top: 93px;
  min-width: 100px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  z-index: 2;
}
.header__navbar-menu {
  margin-top: 30px;
  padding-right: 20px;
  display: flex;
  align-items: end;
  display: none;
}

@media screen and (max-width: 576px) {
  .header__navbar-menu {
    display: block;
  }
  .header {
    display: flex;
    justify-content: space-between;
    background-color: #f8f7f9;
  }
  .header-navlinks {
    display: none;
  }
  .header-heading {
    margin-top: 10px;
    font-size: 20px;
    margin-left: 20px;
    margin-bottom: 40px;
  }
  .header {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .header-navlinks {
    display: none;
  }
  .header__navbar-menu {
    display: block;
  }
  .header {
    display: flex;
    justify-content: space-between;
    background-color: #f8f7f9;
  }
  .header-heading {
    margin-top: 10px;
    font-size: 22px;
    margin-left: 20px;
    margin-bottom: 40px;
  }
}
