/* @font-face {
    font-family: "Avenir";
    src: local("Avenir"), url("path/to/avenir.woff2") format("woff2"),
      url("path/to/avenir.woff") format("woff");
    font-weight: normal;  
    font-style: normal;
  } */
  
  .welcome-heading {
    font-family: "Oswald", sans-serif;
    color: #5570b4;
    font-size: 72px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  
  .welcome-paragraph {
    width: 780px;
    height: 84.38px;
    font-family: "Avenir", sans-serif;
    color: #162b6f;
    font-size: 15px;
    text-align: center;
  }
  @media screen and (max-width: 576px) {
    .welcome-paragraph {
      font-size: 12px;
      width: auto;
      margin-left: 30px;
      margin-right: 30px;
    }
    .welcome-heading {
      width: auto;
      font-size: 36px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .welcome-heading {
      width: auto;
      font-size: 48px;
    }
    .welcome-paragraph {
      font-size: 12px;
      width: auto;
      margin-left: 30px;
      margin-right: 30px;
    }
  }
  
  @media screen and (max-width: 992px) {
    .welcome-paragraph {
      width: auto;
      height: auto;
      margin-left: 40px;
      margin-right: 40px;
    }
  }
  @media screen and (min-width: 992px) {
    .welcome {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
 