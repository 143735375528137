.carousel-img {
    width: 100%;
    height: auto;
    margin-left: 0;
  }
  @media screen and (min-width: 992px) {
    .carousel-img {
      max-width: 100%;
      height: 500px;
      object-fit: contain;
    }
  }
  