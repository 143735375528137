/* @font-face {
    font-family: "Avenir";
    src: local("Avenir"), url("path/to/avenir.woff2") format("woff2"),
      url("path/to/avenir.woff") format("woff");
    font-weight: normal;  
    font-style: normal;
  } */
  
  .footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 42px;
  }
  .footer-heading1 {
    font-family: "Oswald", sans-serif;
    color: #162b6f;
    font-size: 22px;
    line-height: 1.41em;
  }
  .footer-heading2 {
    font-family: "Oswald", sans-serif;
    color: #162b6f;
    font-size: 22px;
    line-height: 1.41em;
  }
  .footer-para1 {
    font-family: "Avenir", sans-serif;
    color: #162b6f;
    font-size: 15px;
    line-height: 1.875em;
  }
  .footer-para2 {
    font-family: "Avenir", sans-serif;
    color: #162b6f;
    font-size: 14px;
    line-height: 1.79em;
    margin: 50px 20px;
  }
  
  @media screen and (max-width: 576px) {
    .footer-heading1 {
      font-size: 18px;
    }
    .footer-heading2 {
      font-size: 18px;
    }
    .footer-para2 {
      margin-top: 20px;
    }
    .footer-para1 {
      margin-top: 20px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .footer-heading1 {
      font-size: 20px;
    }
    .footer-heading2 {
      font-size: 20px;
    }
    .footer-para2 {
      margin-top: 20px;
    }
    .footer-para1 {
      margin-top: 20px;
    }
  }
  