/* @font-face {
  font-family: "Avenir";
  src: local("Avenir"), url("path/to/avenir.woff2") format("woff2"),
    url("path/to/avenir.woff") format("woff");
  font-weight: normal;  
  font-style: normal;
} */

.aboutUs1 {
  width: 100%;
  background-color: #f8f7f9;
  margin-top: 100px;
  display: flex;
  justify-content: center;
}
.aboutUs1-leftSection {
  justify-self: self-start;
  width: 506px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 80px;
  margin-top: 150px;
}
.aboutUs1-heading {
  width: 436.49;
  height: 59.33;
  font-family: "Oswald", sans-serif;
  color: #5570b4;
  font-size: 40px;
  text-align: center;
}
.aboutUs1-paragaraph {
  font-family: "Avenir", sans-serif;
  color: #162b6f;
  width: 460pxpx;
  height: 168.75px;
  line-height: 1.875em;
  font-size: 15px;
  text-align: center;
  margin-top: 25px;
}
.aboutUs1-img {
  width: 506px;
  height: 620px;
  margin-left: 115px;
}

@media screen and (max-width: 576px) {
  .aboutUs1 {
    flex-direction: column;
  }
  .aboutUs1-leftSection {
    width: auto;
    height: auto;
    margin-top: 30px;
    margin-bottom: 40px;
  }
  .aboutUs1-heading {
    width: 100%;
    font-size: 28px;
  }
  .aboutUs1-paragaraph {
    width: 100%;
    font-size: 12px;
  }
  .aboutUs1-img {
    object-fit: contain;
    margin-left: 0;
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .aboutUs1 {
    flex-direction: column;
  }
  .aboutUs1-leftSection {
    width: auto;
    margin-left: 40px;
    margin-right: 30px;
  }
  .aboutUs1-paragaraph {
    width: auto;
  }
}

@media screen and (max-width: 992px) {
  .aboutUs1-leftSection {
    margin-right: 40px;
    margin-top: 25px;
  }
  .aboutUs1-paragaraph {
    width: auto;
  }
  .aboutUs1-img {
    object-fit: contain;
    margin-left: 0;
    width: 100%;
    height: auto;
  }
}

@media screen and (min-width: 1280px) {
 
  .aboutUs1-leftSection {
    margin-right: 200px;
    margin-left: 100px;
  }
}
